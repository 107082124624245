export const iterateKeyValue = function (
  obj,
  callback /* (key, value) => void */
) {
  if (obj === null || Array.isArray(obj) || typeof obj !== 'object') {
    throw new Error('iterateKeyValue: input is not an object');
  }

  Object.keys(obj).forEach((key) => {
    callback(key, obj[key]);
  });
};
