const isRecipePage = window.location.pathname.split('/')?.[1] === 'recipes';

const pageHandle = (node: Element | null, direction: string | undefined) => {
  if (direction === 'down' && isRecipePage)
    node?.classList.add('bottom-sticky--show', 'recipes-bottom');
  else if (direction === 'up' && isRecipePage)
    node?.classList.remove('bottom-sticky--show', 'recipes-bottom');
  else if (direction === 'down') node?.classList.add('bottom-sticky--show');
  else if (direction === 'up') node?.classList.remove('bottom-sticky--show');
};

// For alcohol warning
function setupAlcoholWarningBar() {
  const alcoholBar = document.querySelector('.bottom-sticky-alcohol');

  if (alcoholBar) {
    const upperTriggerPoint = document.querySelector(
      '.bottom-sticky-ad-waypoint--in'
    );
    if (upperTriggerPoint) {
      /* eslint-disable no-new */
      new Waypoint({
        element: upperTriggerPoint as HTMLElement,
        handler(direction) {
          if (direction === 'down' && isRecipePage)
            alcoholBar.classList.add('recipes-bottom');
          else if (direction === 'up' && isRecipePage)
            alcoholBar.classList.remove('recipes-bottom');
        }
      });
    }
  }
}

// For recipes page bottom bar
function setupRecipesBottomBar() {
  const recipesBar = document.querySelector('.recipes-bottom-bar');

  if (recipesBar) {
    const upperTriggerPoint = document.querySelector(
      '.bottom-sticky-ad-waypoint--in'
    );
    if (upperTriggerPoint) {
      /* eslint-disable no-new */
      new Waypoint({
        element: upperTriggerPoint as HTMLElement,
        handler(direction) {
          if (direction === 'down' && isRecipePage)
            recipesBar.classList.add('bottom-sticky--show');
          else if (direction === 'up' && isRecipePage)
            recipesBar.classList.remove('bottom-sticky--show');
        }
      });
    }
  }
}

function setupBottomStickyAd() {
  // Bottom Ads for mobile
  const bottomStickyAd = document.querySelector('.sticky--ad');

  if (bottomStickyAd) {
    // bottomAdAfterGoldWaypoint
    const upperTriggerPoint = document.querySelector(
      '.bottom-sticky-ad-waypoint--in'
    );
    if (upperTriggerPoint) {
      /* eslint-disable no-new */
      new Waypoint({
        element: upperTriggerPoint as HTMLElement,
        handler(direction) {
          pageHandle(bottomStickyAd, direction);
        }
      });
    }

    // bottomAdAfterFuncsWaypoint
    const latterTriggerPoint = document.querySelector(
      '.bottom-sticky-ad-waypoint--out'
    );
    if (latterTriggerPoint) {
      new Waypoint({
        element: latterTriggerPoint as HTMLElement,
        handler(direction) {
          if (direction === 'down' && isRecipePage)
            bottomStickyAd.classList.remove(
              'bottom-sticky--show',
              'recipes-bottom'
            );
          else if (direction === 'up' && isRecipePage)
            bottomStickyAd.classList.add(
              'bottom-sticky--show',
              'recipes-bottom'
            );
          else if (direction === 'down')
            bottomStickyAd.classList.remove('bottom-sticky--show');
          else if (direction === 'up')
            bottomStickyAd.classList.add('bottom-sticky--show');
        },
        offset: '100%'
      });
      /* eslint-enable no-new */
    }
  }
}

function setupBottomStickyVIPEntry() {
  // Bottom noAd banner for Mobile
  const bottomStickyNoAd = document.querySelector('.sticky--noAd');
  if (bottomStickyNoAd) {
    const triggerPoint = document.querySelector('.bottom-sticky-noAd-waypoint');
    if (triggerPoint) {
      /* eslint-disable no-new */
      new Waypoint({
        element: triggerPoint as HTMLElement,
        handler(direction) {
          pageHandle(bottomStickyNoAd, direction);
        },
        offset: '100%'
      });
    }
  }
}

function setupBottomStickyVideo() {
  const adBottomStickyVideo = document.getElementById(
    'iCook_Recipe_Bottom_Sticky_Video'
  );

  const adBottomStickyVideoContainer =
    document.querySelector('.mobile-bottom-ad');
  const adBottomStickyVideoLayout = document.querySelector(
    '.mobile-bottom-ad-layout'
  );

  if (
    adBottomStickyVideo &&
    adBottomStickyVideoContainer &&
    adBottomStickyVideoLayout
  ) {
    const closeButton = document.createElement('a');

    closeButton.classList.add('ad-bottom-sticky-close');
    closeButton.setAttribute('aria-label', '關閉');
    closeButton.innerHTML = '<i class="icon-times-regular"></i>';
    adBottomStickyVideoLayout.appendChild(closeButton);

    closeButton.addEventListener('click', () => {
      adBottomStickyVideoContainer.parentNode!.removeChild(
        adBottomStickyVideoContainer
      );
    });
  }
}

export function setupBottomSticky() {
  setupBottomStickyAd();
  setupBottomStickyVIPEntry();
  setupBottomStickyVideo();
}

export function setupBottomStickyWithoutAds() {
  setupAlcoholWarningBar();
  setupRecipesBottomBar();
}
