import { isClient } from 'reactjs/utils';

export default function perfMark(name) {
  const start = `${name}:start`;
  const end = `${name}:end`;

  if (isClient() && performance.mark && performance.measure) {
    return {
      markStart: () => {
        performance.mark(start);
      },
      markEnd: () => {
        performance.mark(end);
      },
      measure: () => {
        performance.measure(name, start, end);
      }
    };
  }
  // Workaround that jest/jsdom performance API does not have `mark` and `measure` method
  // https://github.com/jsdom/w3c-hr-time#limitations
  return {
    markStart: () => {},
    markEnd: () => {},
    measure: () => {}
  };
}
