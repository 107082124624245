// Try to stick with hand-written ES5 for faster loading without polyfills

import 'waypoints/lib/noframework.waypoints';
import {
  googletag,
  adUnitRegistry,
  DFP_ID,
  CURRENT_INGREDIENTS_TARGETING
} from './dfpConstants';
import setupRefreshAdsInViewport from './dfpRefreshAds';

import sizeMapping from './dfpSizeMapping';
import { iterateKeyValue } from './shams';
import {
  shouldShowAds,
  pageSpeedDisableGroup,
  shouldPerformPrebid
} from './helpers';
import initStickySidebarHandlers from './stickySidebar';
import { setupRecommendWidgets } from './recommendWidgets';
import {
  checkInterstitialAvailability,
  fixInterstitialModal
} from './interstitialAd';
import { setupBottomSticky, setupBottomStickyWithoutAds } from './stickyBottom';

const getUrlTargeting = function () {
  // Get the url and parse it to its component parts using regex from RFC2396 Appendix-B (https://tools.ietf.org/html/rfc2396#appendix-B)
  const urlMatches = window.location
    .toString()
    .match(/^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/);
  const matchedAuthority = urlMatches[4] || '';
  const matchedPath = (urlMatches[5] || '').replace(/(.)\/$/, '$1');
  const matchedQuery = urlMatches[7] || '';
  // Get the query params for targeting against
  const params = matchedQuery.replace(/=/g, ':').split('&');

  return {
    UrlHost: matchedAuthority,
    UrlPath: matchedPath,
    UrlQuery: params
  };
};

const getSizeMappingFromRegistry = function (id) {
  const mappingName = adUnitRegistry[id].size_mapping;
  const mapping = mappingName && sizeMapping[mappingName];
  return mapping;
};

const checkSizeMappingAvailability = function () {
  Object.keys(adUnitRegistry).forEach((id) => {
    const mapping = getSizeMappingFromRegistry(id);
    if (!mapping) return;

    let selectedSize;

    for (let i = 0; i < mapping.length; i += 1) {
      if (
        window.innerWidth >= mapping[i].browser[0] &&
        window.innerHeight >= mapping[i].browser[1]
      ) {
        selectedSize = mapping[i];
        break;
      }
    }

    if (!selectedSize || !selectedSize.ad_sizes.length) {
      delete adUnitRegistry[id];
    }
  });
};

/**
 * @param {googletag.events.SlotRenderEndedEvent} event
 * @param {HTMLElement} element
 */
const onEachAdLoaded = function (event, element) {
  const elementId = event.slot.getSlotElementId();

  if (!window.__iCook_adUnitRegistry[elementId].refreshed) {
    setupRefreshAdsInViewport(element);
  }

  if (
    elementId === 'iCook_Brand_Sidebar_bottom' ||
    elementId === 'iCook_Campaign_Sidebar_Top'
  ) {
    element.classList.add('ad-sidebar-fix');
  }
};

const onSlotRenderEnded = function (event) {
  const elementId = event.slot.getSlotElementId();
  const element = document.getElementById(elementId);

  if (!element || !adUnitRegistry[elementId]) return;
  const originalHTML = adUnitRegistry[elementId].innerHTML;

  if (event.isEmpty && originalHTML) {
    element.classList.add('display-original');
    element.style.setProperty('display', 'block', 'important');
    element.innerHTML = originalHTML;
  } else {
    const display = event.isEmpty ? 'none' : 'block';
    element.classList.add(`display-${display}`);
    element.style.setProperty('display', display, 'important');
  }

  // One may check event.isEmpty to determine if the ad is unfilled
  onEachAdLoaded(event, element);
};

const setupSlot = function (id) {
  // mostly derived from jquery.dfp
  googletag.cmd.push(() => {
    const adUnitPath = `/${DFP_ID}/${id}`;

    const slot = googletag.defineSlot(adUnitPath, [0, 0], id);

    slot.addService(googletag.pubads());

    const { targeting } = adUnitRegistry[id];

    if (targeting) {
      const { ingredients = [] } = targeting;

      const ingredientsTargeting = ingredients.filter((ingredient) =>
        CURRENT_INGREDIENTS_TARGETING.some((currentIngredient) =>
          ingredient.includes(currentIngredient)
        )
      );

      if (ingredientsTargeting.length > 0) {
        targeting.ingredients = ingredientsTargeting;
      } else {
        delete targeting.ingredients;
      }

      iterateKeyValue(targeting, (k, v) => {
        slot.setTargeting(k, v);
      });
    }

    const mapping = getSizeMappingFromRegistry(id);
    if (mapping) {
      const map = googletag.sizeMapping();
      mapping.forEach((v) => {
        map.addSize(v.browser, v.ad_sizes);
      });
      slot.defineSizeMapping(map.build());
    }
  });
};

const setupAllSlots = function () {
  Object.keys(adUnitRegistry).forEach(setupSlot);
};

const setupService = function (hasInterstitial) {
  googletag.cmd.push(() => {
    const pubadsService = googletag.pubads();

    if (shouldPerformPrebid) {
      // delegate request to the refresh() in ad-preflight (prebid)
      pubadsService.disableInitialLoad();
    }

    if (hasInterstitial) {
      pubadsService.setTargeting('context', 'with-interstitial');
    }

    pubadsService.setCentering(true);
    pubadsService.enableLazyLoad({
      fetchMarginPercent: 100,
      renderMarginPercent: 100,
      mobileScaling: 1.0
    });

    iterateKeyValue(getUrlTargeting(), (k, v) => {
      pubadsService.setTargeting(k, v);
    });

    pubadsService.addEventListener('slotRenderEnded', onSlotRenderEnded);
    pubadsService.addEventListener('impressionViewable', fixInterstitialModal);

    googletag.enableServices();
  });
};

const setupDFP = function () {
  const hasInterstitial = checkInterstitialAvailability();
  checkSizeMappingAvailability();

  // Ensure GPT loaded
  googletag.cmd.push(() => {
    setupService(hasInterstitial);
    setupAllSlots();

    // otherwise delegate request to the refresh() in ad-preflight (prebid)
    if (!shouldPerformPrebid) {
      googletag.refresh();
    }
  });
};

const run = () => {
  if (!pageSpeedDisableGroup.includes('dfp')) {
    setupDFP();
  }

  setupBottomSticky();
  setupRecommendWidgets();
  initStickySidebarHandlers();
};

if (shouldShowAds) {
  run();
}

setupBottomStickyWithoutAds();
