import {
  insertScriptTagWithSrc,
  isHkDomain,
  pageSpeedDisableGroup
} from '../helpers';

function loadMatchedContent() {
  if (document.querySelector('#_popIn_recommend')) {
    isHkDomain
      ? insertScriptTagWithSrc(
          `${window.location.protocol}//api.popin.cc/searchbox/icook_HK.js`
        )
      : insertScriptTagWithSrc(
          `${window.location.protocol}//api.popin.cc/searchbox/icook_recipes.js`
        );
  }
}

// 猜你可能會喜歡 MatchedContent
export function setupMatchedContent() {
  if (!pageSpeedDisableGroup.includes('MatchedContent')) {
    const adTargetElement = document.querySelector('#matched-content');
    const waypointElement = document.querySelector('.matched-content-waypoint');
    if (adTargetElement && waypointElement) {
      /* eslint-disable no-new */
      new Waypoint({
        element: waypointElement as HTMLElement,
        handler(direction) {
          if (direction === 'down') {
            loadMatchedContent();
            this.destroy();
          }
        },
        offset: '50%'
      });
      /* eslint-enable no-new */
    }
  }
}
