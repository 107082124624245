import { Component } from 'react';
import PropTypes from 'prop-types';
import Select from './select';
import { INGREDIENT_SEARCH_INPUT_PLACEHOLDER } from './constants';

export default class RecipeMode extends Component {
  static isValidate(val) {
    const str = val.replace(/\s/g, '');
    return !!str.length;
  }

  static renderMulti(tags, placeholder = '') {
    const hasNoTags = !!placeholder.length;
    return (
      <div className="search-ingredient-tags" tabIndex={0}>
        {hasNoTags && (
          <span className="search-ingredient-placeholder">{placeholder}</span>
        )}
        {!hasNoTags &&
          tags.map((tag, idx) => (
            <span className="ingredient-tag" key={`tag-${idx}`}>
              {tag}
            </span>
          ))}
      </div>
    );
  }

  constructor(props, context) {
    super(props, context);

    this.setValue = this.setValue.bind(this);
    this.getQOpts = this.getQOpts.bind(this);
    this.getIngredientOpts = this.getIngredientOpts.bind(this);

    this.state = {
      q: props.q,
      ingredients: props.ingredients.split(',').join(props.delimiter)
    };
  }

  componentDidMount() {
    this._timerQ = null;
    this._timerIngredient = null;

    const { q, ingredients } = this.state;
    this.props.setSubmitState(
      RecipeMode.isValidate(q) || RecipeMode.isValidate(ingredients)
    );
  }

  setValue(key) {
    return (val) => {
      const data = { [key]: val };
      const otherKey = key === 'q' ? 'ingredients' : 'q';
      this.props.setSubmitState(
        RecipeMode.isValidate(val) ||
          RecipeMode.isValidate(this.state[otherKey])
      );
      this.setState(data);
    };
  }

  getQOpts(input, caretInfo, cb) {
    const caret =
      caretInfo.start === caretInfo.end ? caretInfo.start : caretInfo.end;
    const beforeCaret = input.slice(0, caret);
    const { autocompleteUrl, ajaxOpts } = this.props;
    if (beforeCaret.length && /^\S+$/i.test(beforeCaret)) {
      if (this._timerQ) {
        clearTimeout(this._timerQ);
      }
      this._timerQ = setTimeout(() => {
        ajaxOpts(
          autocompleteUrl,
          { q: beforeCaret },
          {
            done(options) {
              cb(null, { options: options.slice(0, 5) });
            },
            fail() {
              cb(null, { options: [] });
            }
          },
          'recipe'
        );
      }, 250);
    } else {
      cb(null, { options: [] });
    }
  }

  getIngredientOpts(input, caretInfo, cb) {
    const caret =
      caretInfo.start === caretInfo.end ? caretInfo.start : caretInfo.end;
    const { delimiter, autocompleteUrl, ajaxOpts } = this.props;
    const beforeCaret = input.slice(0, caret);
    const theLast = beforeCaret.split(delimiter).pop();
    if (theLast.length && /^\S+$/i.test(theLast)) {
      if (this._timerIngredient) {
        clearTimeout(this._timerIngredient);
      }
      this._timerIngredient = setTimeout(() => {
        ajaxOpts(
          autocompleteUrl,
          { q: theLast },
          {
            done(options) {
              cb(null, { options: options.slice(0, 5) });
            },
            fail() {
              cb(null, { options: [] });
            }
          },
          'ingredient'
        );
      }, 250);
    } else {
      cb(null, { options: [] });
    }
  }

  renderOptions(key) {
    let query = this.state[key];
    query = query.replace(
      /[\.\\\+\*\?\[\^\]\$\(\)\{\}=!<>\|:\-]/g,
      (match) => `\\${match}`
    );
    return (opt) => {
      const tpl = opt.value.replace(
        new RegExp(query, 'ig'),
        `<strong class='search-match-highlight'>${query}</strong>`
      );
      return <span dangerouslySetInnerHTML={{ __html: tpl }} />;
    };
  }

  renderQ(q) {
    // design value format to string
    return (
      <Select
        className="search-recipe"
        name="q"
        options={this.getQOpts}
        placeholder="搜尋食譜名"
        value={q}
        noResultsText=""
        loadingText="搜尋中..."
        searchingText="搜尋中..."
        custom={this.renderOptions('q')}
        onChange={this.setValue('q')}
      />
    );
  }

  renderIngredient(ingredients, delimiter) {
    // design value format to string
    return (
      <Select
        multi
        className="search-ingredient"
        delimiter={delimiter}
        name="ingredients"
        options={this.getIngredientOpts}
        placeholder={INGREDIENT_SEARCH_INPUT_PLACEHOLDER}
        value={ingredients}
        noResultsText=""
        loadingText="搜尋中..."
        searchingText="搜尋中..."
        inputBlurTpl={RecipeMode.renderMulti}
        customTpl={this.renderOptions('ingredients', 'multi')}
        onChange={this.setValue('ingredients')}
      />
    );
  }

  render() {
    const { q, ingredients } = this.state;
    const { delimiter } = this.props;
    return (
      <div className="search-by-keyword">
        {this.renderQ(q)}
        {this.renderIngredient(ingredients, delimiter)}
      </div>
    );
  }
}

RecipeMode.propTypes = {
  ajaxOpts: PropTypes.func,
  autocompleteUrl: PropTypes.string,
  delimiter: PropTypes.string,
  ingredients: PropTypes.string,
  q: PropTypes.string,
  searchUrl: PropTypes.string,
  searchUserUrl: PropTypes.string,
  setActionPath: PropTypes.func,
  setSubmitState: PropTypes.func
};

RecipeMode.defaultProps = {
  ajaxOpts() {},
  autocompleteUrl: '',
  delimiter: ' ',
  ingredients: '',
  q: '',
  searchUrl: '',
  searchUserUrl: '',
  setActionPath() {},
  setSubmitState() {}
};
