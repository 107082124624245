import { layoutDisableScroll } from '../lib/layoutUtils';

const navbar = document.getElementById('global-navbar');
const headerCategories = document.getElementById('header-categories');
const dropdownTrigger = document.getElementById('dropdown-categories-trigger');
const dropdownTriggerIcon = dropdownTrigger?.querySelector('i');
const dropdown = document.getElementById('dropdown-categories');
const dropdownBackdrop = document.getElementById(
  'dropdown-categories-backdrop'
);
const mobileDropdownBackdrop = document.getElementById(
  'mobile-dropdown-categories-backdrop'
);

function modifyDropdownPosition() {
  dropdownBackdrop!.style.top = `${
    headerCategories!.getBoundingClientRect().bottom
  }px`;

  mobileDropdownBackdrop!.style.top = `${
    navbar!.getBoundingClientRect().bottom
  }px`;
}

function initHeaderDropdownCategories() {
  if (
    !headerCategories ||
    !dropdownTrigger ||
    !dropdownTriggerIcon ||
    !dropdown ||
    !dropdownBackdrop ||
    !mobileDropdownBackdrop
  )
    return;

  let hidden = true;

  function updateState() {
    if (hidden) {
      dropdownTriggerIcon!.className = 'icon-chevron-down-regular';

      dropdownBackdrop!.classList.add('hidden');
      mobileDropdownBackdrop!.classList.add('hidden');

      layoutDisableScroll(false);
    } else {
      layoutDisableScroll(true);

      dropdownTriggerIcon!.className = 'icon-chevron-up-regular';

      modifyDropdownPosition();

      if (window.innerWidth < 768) {
        mobileDropdownBackdrop!.classList.remove('hidden');
      } else {
        dropdownBackdrop!.classList.remove('hidden');
      }
    }
  }

  function setHidden(_hidden: boolean) {
    hidden = _hidden;
    updateState();
  }

  dropdown.addEventListener('click', (e) => {
    e.stopPropagation();
  });

  dropdownTrigger.addEventListener('click', () => {
    setHidden(!hidden);
  });

  dropdownBackdrop.addEventListener('click', () => {
    setHidden(true);
  });

  mobileDropdownBackdrop.addEventListener('click', () => {
    setHidden(true);
  });

  window.addEventListener(
    'resize',
    () => {
      if (
        window.innerWidth < 768 &&
        !dropdownBackdrop.classList.contains('hidden')
      ) {
        dropdownBackdrop.classList.add('hidden');
        mobileDropdownBackdrop.classList.remove('hidden');
      } else if (
        window.innerWidth >= 768 &&
        !mobileDropdownBackdrop.classList.contains('hidden')
      ) {
        mobileDropdownBackdrop.classList.add('hidden');
        dropdownBackdrop.classList.remove('hidden');
      }

      modifyDropdownPosition();
    },
    true
  );
}

initHeaderDropdownCategories();

export {};
