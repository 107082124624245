/* eslint-disable no-var, vars-on-top, prefer-arrow-callback, func-names, prefer-template, prefer-rest-params, prefer-spread, camelcase, object-shorthand */

import { googletag } from './dfpConstants';

const adContainerInViewport = function (adunit) {
  const bounding = adunit.getBoundingClientRect();
  return (
    document.body.contains(adunit) &&
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

const setupRefreshAdsInViewport = function (ele) {
  if (
    ['iCook_Recipe_Sidebar_Bottom', 'iCook_Recipes_Sidebar_Bottom'].includes(
      ele.id
    )
  ) {
    let timer = null;
    const scrollListener = function () {
      if (adContainerInViewport(ele)) {
        if (timer !== null) return;
        timer = setTimeout(function () {
          if (adContainerInViewport(ele)) {
            window.removeEventListener('scroll', scrollListener);
            const adunit = googletag
              .pubads()
              .getSlots()
              .filter(function (s) {
                return s.getSlotElementId() === ele.id;
              });

            Object.defineProperty(
              window.__iCook_adUnitRegistry[ele.id],
              'refreshed',
              {
                value: true,
                writable: false
              }
            );

            googletag.pubads().refresh(adunit);
          }
        }, 60000);
      } else {
        timer = null;
        clearTimeout(timer);
      }
    };
    window.addEventListener('scroll', scrollListener);
  }
};

export default setupRefreshAdsInViewport;
