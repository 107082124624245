function openDropdown() {
  const button = document.getElementById('header-keywords-ranking-trigger');
  if (button) {
    button.addEventListener('click', () => {
      const rankingList = document.querySelector('.popular-keywords-ranking');
      const buttonIcon = button.querySelector('i');
      const isClose = buttonIcon.className.includes('down');
      buttonIcon.className = isClose
        ? 'icon-caret-up-solid'
        : 'icon-caret-down-solid';
      if (isClose && rankingList.hasAttribute('hidden')) {
        rankingList.removeAttribute('hidden');
      } else {
        rankingList.setAttribute('hidden', '');
      }
    });
  }
}

openDropdown();
