import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Option extends Component {
  static renderDefault(info) {
    return <span>{info.label || info.value}</span>;
  }

  constructor(props, context) {
    super(props, context);

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    this._clickOptTimer = null;
  }

  onMouseEnter() {
    const { info, onMouseEnter } = this.props;
    if (info.completed) {
      onMouseEnter(info);
    }
  }

  onMouseDown() {
    if (this._clickOptTimer) {
      clearTimeout(this._clickOptTimer);
    }
    this._clickOptTimer = setTimeout(() => {
      this.onClick();
    }, 10);
  }

  onClick(e) {
    const { info, onClick } = this.props;
    if (this._clickOptTimer) {
      clearTimeout(this._clickOptTimer);
      this._clickOptTimer = null;
    }
    if (info.completed) {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }
      onClick(info);
    }
  }

  render() {
    const { info, customTpl, isFocus, ...props } = this.props;
    const classes = classNames(
      'search-keyword-preview-item',
      props.customClass,
      {
        'preview-item-active': isFocus
      }
    );

    return (
      <li
        className={classes}
        onMouseDown={this.onMouseDown}
        onClick={this.onClick}
        onMouseEnter={this.onMouseEnter}
      >
        {customTpl ? customTpl(info) : Option.renderDefault(info)}
      </li>
    );
  }
}

Option.propTypes = {
  customClass: PropTypes.string,
  customTpl: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  info: PropTypes.shape({}),
  isFocus: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func
};

Option.defaultProps = {
  customClass: '',
  customTpl: false, // or func
  info: {}, // label, value, completed
  isFocus: false,
  onClick() {},
  onMouseEnter() {}
};
