import { Component } from 'react';
import PropTypes from 'prop-types';
import RecipeMode from './recipeMode';
import {
  AUTHOR_SEARCH_INPUT_PLACEHOLDER,
  MAX_SEARCH_WORDS_LENGTH
} from './constants';

export default class DeskSearch extends Component {
  static isValidate(val) {
    const str = val.replace(/\s/g, '');
    return !!str.length;
  }

  static isUrl(url, searchUrl) {
    return new RegExp(searchUrl).test(url);
  }

  constructor(props, context) {
    super(props, context);

    this.onSelectChange = this.onSelectChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);

    const activeTabs = DeskSearch.isUrl(props.url, props.searchUserUrl)
      ? 'user'
      : 'recipe';

    this.state = { activeTabs };
  }

  componentDidMount() {
    const { activeTabs } = this.state;
    const { q } = this.props;
    if (activeTabs === 'user') {
      this.props.setActionPath(activeTabs);
      this.props.setSubmitState(DeskSearch.isValidate(q));
    }
  }

  onSelectChange(e) {
    const type = e.target.value;
    this.props.setActionPath(type);
    this.setState({
      activeTabs: type
    });
  }

  onInputChange(e) {
    const val = e.target.value;
    if (val.length) {
      this.props.setSubmitState(DeskSearch.isValidate(val));
    }
  }

  renderUser(props) {
    return (
      <input
        className="search-by-author"
        defaultValue={props.q}
        name="q"
        onChange={this.onInputChange}
        placeholder={AUTHOR_SEARCH_INPUT_PLACEHOLDER}
        maxLength={MAX_SEARCH_WORDS_LENGTH}
      />
    );
  }

  render() {
    const { ...props } = this.props;
    const { activeTabs } = this.state;
    return (
      <div className="search-bar">
        <div className="search-by-type-container">
          <select
            className="search-by-type"
            value={activeTabs}
            onChange={this.onSelectChange}
          >
            <option value="recipe">找食譜</option>
            <option value="user">找作者</option>
          </select>
          <span className="search-by-type-icon">
            <i className="icon-caret-down-solid" />
          </span>
        </div>
        {activeTabs === 'recipe' ? (
          <RecipeMode {...props} />
        ) : (
          this.renderUser(props)
        )}
      </div>
    );
  }
}

DeskSearch.propTypes = {
  ajaxOpts: PropTypes.func,
  autocompleteUrl: PropTypes.string,
  delimiter: PropTypes.string,
  ingredients: PropTypes.string,
  q: PropTypes.string,
  searchUrl: PropTypes.string,
  searchUserUrl: PropTypes.string,
  setActionPath: PropTypes.func,
  setSubmitState: PropTypes.func,
  url: PropTypes.string
};

DeskSearch.defaultProps = {
  ajaxOpts() {},
  autocompleteUrl: '',
  delimiter: ' ',
  ingredients: '',
  q: '',
  searchUrl: '',
  searchUserUrl: '',
  setActionPath() {},
  setSubmitState() {},
  url: ''
};
