import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Option from './option';
import { MAX_SEARCH_WORDS_LENGTH } from './constants';

export default class Select extends Component {
  static createSelectClass(classes, isFocus) {
    return classNames('search-container', 'select-input', classes, {
      'blur-input': !isFocus,
      'focus-input': isFocus
    });
  }

  static getMultiValArr(inputVal, delimiter) {
    const reform = inputVal
      .replace(new RegExp(`(^${delimiter}+)|(${delimiter}+$)`, 'gi'), '')
      .replace(new RegExp(`${delimiter}+`, 'gi'), delimiter);
    return reform.length ? reform.split(delimiter) : [];
  }

  static getFocusOpt(currentFocus, options, dirc = 'next') {
    const idx = options.indexOf(currentFocus);
    let result = options[0] || {};
    if (idx > -1) {
      let dircIdx = dirc === 'next' ? idx + 1 : idx - 1;
      dircIdx += options.length;
      result = options[dircIdx % options.length];
    }
    return result;
  }

  static renderLoading(loadingText) {
    return <p className="search-status-text">{loadingText}</p>;
  }

  static renderSearching(searchingText) {
    return <p className="search-status-text">{searchingText}</p>;
  }

  static renderNoResult(noResultsText) {
    return <p className="search-status-text">{noResultsText}</p>;
  }

  static renderInputBlur(inputVal, delimiter, placeholder, tplFunc) {
    const inputs = Select.getMultiValArr(inputVal, delimiter);
    return tplFunc(inputs, inputs.length ? '' : placeholder);
  }

  constructor(props, context) {
    super(props, context);

    this.onClickOption = this.onClickOption.bind(this);
    this.onMouseEnterOption = this.onMouseEnterOption.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);

    this.state = {
      focusOpt: {},
      isFocus: false,
      loading: false,
      options: /function/gi.test(Object.prototype.toString.apply(props.options))
        ? []
        : props.options,
      searching: false,
      value: props.value || ''
    };
  }

  componentDidMount() {
    this.requestId = -1;
    this.blurTimer = null;
    this.resetState();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const propsValue = this.props.value;
    return (
      this.state !== nextState ||
      (propsValue !== nextProps.value && propsValue !== nextState.value)
    );
  }

  onMouseEnterOption(info) {
    this.setFocusOpt(info);
  }

  onClickOption() {
    const { inputRef } = this;
    const { multi, delimiter } = this.props;
    const { value, focusOpt } = this.state;
    this.setValue(multi, value, focusOpt, delimiter, inputRef);
    if (inputRef) {
      inputRef.focus();
    }
  }

  onBlur() {
    const theIns = this;
    const theNode = theIns.boxRef;
    if (theIns.blurTimer) {
      clearTimeout(theIns.blurTimer);
    }
    theIns.blurTimer = setTimeout(() => {
      if (theNode.querySelector('input') !== document.activeElement) {
        theIns.setState({
          isFocus: false,
          focusOpt: {}
        });
      }
    }, 10);
  }

  onFocus() {
    if (!this.state.isFocus) {
      this.setState({
        isFocus: true
      });
    }
  }

  onInputFocus(e) {
    const { target } = e;
    // set cursor at the end
    // (setSelectionRange may not surpport in mobile
    if (target.setSelectionRange) {
      const { value } = this.state;
      target.setSelectionRange(value.length, value.length);
    }
  }

  onChange(e) {
    const { target } = e;
    if (target.value !== this.state.value) {
      this.updateOptions(target);
      this.props.onChange(target.value);
    }
  }

  onKeyDown(e) {
    const { target } = e;
    const { multi, delimiter } = this.props;
    const { focusOpt, options, value } = this.state;
    let dirc;
    switch (true) {
      case e.key === 'Enter':
        if (focusOpt.value && e) {
          e.preventDefault();
        }
        dirc = '';
        this.setValue(multi, value, focusOpt, delimiter, target);
        break;
      case e.key === 'ArrowUp':
        if (e) {
          e.preventDefault();
        }
        dirc = 'prev';
        break;
      case e.key === 'ArrowDown':
        if (e) {
          e.preventDefault();
        }
        dirc = 'next';
        break;
      default:
        dirc = '';
    }
    if (dirc.length) {
      const guessOpt = Select.getFocusOpt(focusOpt, options, dirc);
      if (guessOpt !== focusOpt) {
        this.setFocusOpt(guessOpt);
      }
    }
  }

  setValue(multi, currentVal, focusOpt, delimiter, inputNode) {
    if (focusOpt.value) {
      let newVal = '';
      if (multi) {
        let caret = inputNode.selectionStart;
        const beforeCaret = currentVal.slice(0, caret);
        const afterCaret = currentVal.slice(caret).split(delimiter);
        afterCaret.splice(0, 1);
        newVal = beforeCaret.split(delimiter);
        const halfWord = newVal.splice(-1, 1, focusOpt.value);
        newVal = newVal.concat(afterCaret).join(delimiter);
        if (inputNode.setSelectionRange) {
          caret += focusOpt.value.length - halfWord.length;
          inputNode.setSelectionRange(caret, caret);
        }
      } else {
        newVal = focusOpt.value;
      }
      this.setState({
        focusOpt: {},
        options: [],
        value: newVal
      });
    }
  }

  setFocusOpt(val) {
    this.setState({ focusOpt: val });
  }

  getResonseHandler() {
    const requestId = (this.currentRequestId = this.requestId);
    const { inputRef } = this;
    this.requestId += 1;
    this.setState({
      loading: false,
      searching: true
    });

    return (error, data) => {
      if (error) {
        return;
      }
      if (!inputRef) {
        return;
      }
      if (requestId !== this.currentRequestId) {
        return;
      }
      const options = (data && data.options) || [];
      this.setState({
        focusOpt: {},
        options,
        searching: false
      });
    };
  }

  get blurTimer() {
    return this._blurTimer;
  }

  get currentRequestId() {
    return this._currentRequestId;
  }

  get requestId() {
    return this._requestId;
  }

  set blurTimer(val) {
    this._blurTimer = val;
  }

  set currentRequestId(val) {
    this._currentRequestId = val;
  }

  set requestId(val) {
    this._requestId = val;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.force2Update !== nextProps.force2Update) {
      this.updateOptions(null);
      if (this.blurTimer) {
        clearTimeout(this.blurTimer);
        this.blurTimer = null;
      }
    }
  }

  updateOptions(target = null) {
    const { focusOpt } = this.state;
    let { value } = this.state;
    const { options } = this.props;
    let start = 0;
    let end = value.length;
    const optsIsFunc = /function/gi.test(
      Object.prototype.toString.apply(options)
    );
    let newState = {
      loading: true,
      options: [],
      searching: false
    };
    if (target) {
      value = target.value;
      start = target.selectionStart;
      end = target.selectionEnd;
      if (this.state.value !== value) {
        newState = Object.assign(newState, { value });
      }
    }
    this.setState(newState);
    if (optsIsFunc && (focusOpt.value !== value || !target)) {
      const responseHandler = this.getResonseHandler();
      options(value, { start, end }, responseHandler);
    }
  }

  resetState() {
    this.currentRequestId = -1;
  }

  renderInput(inputVal, name, props, isFocus) {
    return (
      <input
        ref={(ref) => (this.inputRef = ref)}
        className="search-input"
        aria-label={props.placeholder}
        autoFocus={isFocus}
        autoComplete="off"
        name={name}
        placeholder={props.placeholder}
        value={inputVal}
        onFocus={this.onInputFocus}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        maxLength={MAX_SEARCH_WORDS_LENGTH}
      />
    );
  }

  renderOptions(options, theState, headerTpl, footerTpl) {
    const { loading, searching } = theState;
    const { loadingText, searchingText, noResultsText, customTpl } = this.props;
    const isReady = !(loading || searching);
    const { focusOpt } = theState;

    if (!(headerTpl || footerTpl)) {
      // no input value
      if (!(theState.value && theState.value.length)) {
        return false;
      }

      if (isReady) {
        if (!(options.length || noResultsText.length)) {
          return false;
        }
      } else if (
        (loading && !loadingText.length) ||
        (searching && !searchingText.length)
      ) {
        return false;
      }
    }
    return (
      <div className="search-keyword-options animated fadeIn">
        {headerTpl}
        {loading && Select.renderLoading(loadingText)}
        {searching && Select.renderSearching(searchingText)}
        {isReady &&
          !options.length &&
          !!noResultsText.length &&
          Select.renderNoResult(noResultsText)}
        <ul className="search-keyword-preview">
          {isReady &&
            !!options.length &&
            options.map((opt, idx) => {
              let isFocus = false;
              if (focusOpt.value && focusOpt === opt) {
                isFocus = true;
              }
              // Option must adjust shouldComponentUpdate
              return (
                <Option
                  customTpl={customTpl}
                  info={opt}
                  isFocus={isFocus}
                  key={`opt-${idx}`}
                  onClick={this.onClickOption}
                  onMouseEnter={this.onMouseEnterOption}
                />
              );
            })}
        </ul>
        {footerTpl}
      </div>
    );
  }

  render() {
    const {
      className,
      inputBlurTpl,
      multi,
      delimiter,
      joinDelimiter,
      name,
      ...props
    } = this.props;
    const { isFocus, options, ...state } = this.state;
    const classes = Select.createSelectClass(className, isFocus);
    return (
      <div
        className={classes}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        ref={(ref) => (this.boxRef = ref)}
      >
        {multi && (
          <input
            type="hidden"
            name={name}
            value={Select.getMultiValArr(state.value, delimiter).join(
              joinDelimiter
            )}
          />
        )}
        {!isFocus && inputBlurTpl && multi
          ? Select.renderInputBlur(
              state.value,
              delimiter,
              props.placeholder,
              inputBlurTpl
            )
          : this.renderInput(state.value, multi ? '' : name, props, isFocus)}
        {isFocus &&
          this.renderOptions(options, state, props.headerTpl, props.footerTpl)}
      </div>
    );
  }
}

Select.propTypes = {
  className: PropTypes.string,
  customTpl: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  delimiter: PropTypes.string,
  footerTpl: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  force2Update: PropTypes.string,
  headerTpl: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  inputBlurTpl: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  joinDelimiter: PropTypes.string,
  loadingText: PropTypes.string,
  multi: PropTypes.bool,
  name: PropTypes.string.isRequired,
  noResultsText: PropTypes.string,
  onChange: PropTypes.func,
  onClickOption: PropTypes.func,
  optTpl: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
  placeholder: PropTypes.string,
  searchingText: PropTypes.string,
  value: PropTypes.string
};

Select.defaultProps = {
  className: '',
  customTpl: false, // or func
  delimiter: ' ',
  footerTpl: false, // or tpl
  force2Update: '',
  headerTpl: false, // or tpl
  inputBlurTpl: false, // or func
  joinDelimiter: ',',
  loadingText: 'loading...',
  multi: false,
  name: '',
  noResultsText: 'no result',
  onChange() {},
  onClickOption() {},
  optTpl() {},
  options: [], // or func
  placeholder: '',
  searchingText: 'searching...',
  value: ''
};
