import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from './select';
import { MOBILE_SEARCH_INPUT_PLACEHOLDER } from './constants';

export default class MobileSearch extends Component {
  static isUrl(url, searchUrl) {
    return new RegExp(searchUrl).test(url);
  }

  static isValidate(val) {
    const str = val.replace(/\s/g, '');
    return !!str.length;
  }

  constructor(props, context) {
    super(props, context);

    this.getQOpts = this.getQOpts.bind(this);
    this.setValue = this.setValue.bind(this);
    this.onClickRecipe = this.onClickRecipe.bind(this);
    this.onClickIngredient = this.onClickIngredient.bind(this);
    this.onClickUser = this.onClickUser.bind(this);

    let q;
    let activeTabs;
    switch (true) {
      case !!props.q.length: {
        q = props.q;
        activeTabs = MobileSearch.isUrl(props.url, props.searchUserUrl)
          ? 'user'
          : 'recipe';
        break;
      }
      case !!props.ingredients.length:
        q = props.ingredients.split(',').join(props.delimiter);
        activeTabs = 'ingredients';
        break;
      default:
        q = '';
        activeTabs = 'recipe';
    }

    this.state = { activeTabs, q };
  }

  componentDidMount() {
    this._timerQ = null;
    const { q } = this.state;
    this.props.setSubmitState(MobileSearch.isValidate(q));
  }

  onClickRecipe(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const { activeTabs } = this.state;
    if (activeTabs !== 'recipe') {
      this.props.setActionPath('recipe');
      this.setState({ activeTabs: 'recipe' });
    }
  }

  onClickIngredient(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const { activeTabs } = this.state;
    if (activeTabs !== 'ingredients') {
      this.props.setActionPath('recipe');
      this.setState({ activeTabs: 'ingredients' });
    }
  }

  onClickUser(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const { activeTabs } = this.state;
    if (activeTabs !== 'user') {
      this.props.setActionPath('user');
      this.setState({ activeTabs: 'user' });
    }
  }

  setValue(key) {
    return (val) => {
      const data = { [key]: val };
      this.props.setSubmitState(MobileSearch.isValidate(val));
      this.setState(data);
    };
  }

  getQOpts(input, caretInfo, cb) {
    const caret =
      caretInfo.start === caretInfo.end ? caretInfo.start : caretInfo.end;
    const { delimiter, autocompleteUrl, ajaxOpts } = this.props;
    const beforeCaret = input.slice(0, caret).split(delimiter);
    const theLast = beforeCaret.pop();
    const { activeTabs } = this.state;
    if (!(theLast.length && /^\S+$/i.test(theLast) && activeTabs !== 'user')) {
      cb(null, { options: [] });
      return;
    }
    if (this._timerQ) clearTimeout(this._timerQ);
    const afterCaret = input.slice(caret).split(delimiter);
    afterCaret.splice(0, 1);
    this._timerQ = setTimeout(() => {
      ajaxOpts(
        autocompleteUrl,
        { q: theLast },
        {
          done(options) {
            const result = options.slice(0, 5).map((opt) => {
              const item = Object.assign({}, opt, {
                value: beforeCaret.concat([opt.value], afterCaret).join(' ')
              });
              return item;
            });
            cb(null, { options: result });
          },
          fail() {
            cb(null, { options: [] });
          }
        },
        activeTabs,
        'isMobile'
      );
    }, 250);
  }

  headerTpl() {
    const { activeTabs } = this.state;
    return (
      <ul className="search-keyword-options-container">
        <li className="search-keyword-option-item">
          <a
            className={`search-keyword-option-link ${classNames({
              'search-option-active': activeTabs === 'recipe'
            })}`}
            href="#"
            onClick={this.onClickRecipe}
          >
            依食譜
          </a>
        </li>
        <li className="search-keyword-option-item">
          <a
            className={`search-keyword-option-link ${classNames({
              'search-option-active': activeTabs === 'ingredients'
            })}`}
            href="#"
            onClick={this.onClickIngredient}
          >
            依食材
          </a>
        </li>
        <li className="search-keyword-option-item">
          <a
            className={`search-keyword-option-link ${classNames({
              'search-option-active': activeTabs === 'user'
            })}`}
            href="#"
            onClick={this.onClickUser}
          >
            找作者
          </a>
        </li>
      </ul>
    );
  }

  createOptionsTpl(key) {
    const { searchUrl, searchUserUrl } = this.props;
    const { activeTabs } = this.state;
    const queryKey = activeTabs === 'ingredients' ? 'ingredients' : 'q';
    const url = activeTabs === 'user' ? searchUserUrl : searchUrl;
    let query = this.state[key];
    query = query.replace(
      /[\.\\\+\*\?\[\^\]\$\(\)\{\}=!<>\|:\-]/g,
      (match) => `\\${match}`
    );

    return (opt) => {
      const tpl = opt.value.replace(
        new RegExp(query, 'ig'),
        `<strong class='search-match-highlight'>${query}</strong>`
      );
      return (
        <a
          className="search-keyword-preview-link"
          href={`${url}?${queryKey}=${opt.value}`}
        >
          <span
            className="search-keyword-preview-text"
            dangerouslySetInnerHTML={{ __html: tpl }}
          />
        </a>
      );
    };
  }

  render() {
    const { q, activeTabs } = this.state;
    const isSearchingIngredient = activeTabs === 'ingredients';
    const name = isSearchingIngredient ? 'ingredients' : 'q';

    return (
      <Select
        multi={isSearchingIngredient}
        name={name}
        placeholder={MOBILE_SEARCH_INPUT_PLACEHOLDER}
        headerTpl={this.headerTpl()}
        options={this.getQOpts}
        value={q}
        force2Update={activeTabs}
        noResultsText=""
        loadingText="搜尋中..."
        searchingText="搜尋中..."
        customTpl={this.createOptionsTpl('q')}
        onChange={this.setValue('q')}
      />
    );
  }
}

MobileSearch.propTypes = {
  ajaxOpts: PropTypes.func,
  autocompleteUrl: PropTypes.string,
  delimiter: PropTypes.string,
  ingredients: PropTypes.string,
  q: PropTypes.string,
  searchUrl: PropTypes.string,
  searchUserUrl: PropTypes.string,
  setActionPath: PropTypes.func,
  setSubmitState: PropTypes.func,
  url: PropTypes.string
};

MobileSearch.defaultProps = {
  ajaxOpts() {},
  autocompleteUrl: '',
  delimiter: ' ',
  ingredients: '',
  q: '',
  searchUrl: '',
  searchUserUrl: '',
  setActionPath() {},
  setSubmitState() {},
  url: ''
};
