/* eslint-disable no-var */
const sizeMapping = {
  interstitial: [
    {
      browser: [300, 400],
      ad_sizes: [[320, 480]]
    },
    {
      browser: [768, 400],
      ad_sizes: []
    }
  ],
  videoblend: [
    {
      browser: [0, 400],
      ad_sizes: ['fluid', [300, 250]]
    },
    {
      browser: [768, 400],
      ad_sizes: ['fluid', [1, 1], [728, 90]]
    }
  ],
  sidebar: [
    {
      browser: [992, 400],
      ad_sizes: [[250, 250]]
    },
    {
      browser: [1200, 400],
      ad_sizes: [
        [300, 250],
        [336, 280]
      ]
    }
  ],
  skyscraper: [
    {
      browser: [992, 400],
      ad_sizes: [[250, 250]]
    },
    {
      browser: [1200, 400],
      ad_sizes: [
        [360, 600],
        [300, 600]
      ]
    }
  ],
  skyscraper_medium: [
    {
      browser: [992, 400],
      ad_sizes: [[250, 250]]
    },
    {
      browser: [1200, 400],
      ad_sizes: []
    }
  ],
  skyscraper_medium_2: [
    {
      browser: [1200, 400],
      ad_sizes: [
        [300, 250],
        [336, 280]
      ]
    }
  ],
  gold: [
    {
      browser: [768, 400],
      ad_sizes: [
        [300, 250],
        [336, 280]
      ]
    },
    {
      browser: [992, 400],
      ad_sizes: [[300, 250]]
    },
    {
      browser: [1200, 400],
      ad_sizes: [
        [300, 250],
        [336, 280]
      ]
    }
  ],
  gold_mobile: [
    {
      browser: [0, 400],
      ad_sizes: [
        [300, 250],
        [336, 280]
      ]
    },
    {
      browser: [768, 400],
      ad_sizes: []
    }
  ],
  gold_mobile_top: [
    {
      browser: [0, 400],
      ad_sizes: [
        [1, 1],
        [300, 250],
        [336, 280]
      ]
    },
    {
      browser: [768, 400],
      ad_sizes: []
    }
  ],
  gold_homepage: [
    {
      browser: [0, 400],
      ad_sizes: [[300, 250]]
    },
    {
      browser: [336, 400],
      ad_sizes: [
        [300, 250],
        [336, 280]
      ]
    },
    {
      browser: [1200, 400],
      ad_sizes: [
        [750, 90],
        [728, 90]
      ]
    }
  ],
  gold_sticky: [
    {
      browser: [0, 0],
      ad_sizes: [[320, 50]]
    },
    {
      browser: [336, 600],
      ad_sizes: [
        [320, 100],
        [1, 1]
      ]
    },
    {
      browser: [768, 0],
      ad_sizes: []
    }
  ],
  gold_desktop_sticky: [
    {
      browser: [992, 400],
      ad_sizes: [
        [300, 250],
        [336, 280]
      ]
    }
  ],
  gold_sticky_video: [
    {
      browser: [0, 0],
      ad_sizes: ['fluid']
    }
  ],
  owned_native: [
    {
      browser: [0, 0],
      ad_sizes: ['fluid']
    }
  ],
  brand_skyscraper: [
    {
      browser: [1200, 400],
      ad_sizes: [
        [360, 600],
        [300, 600]
      ]
    }
  ],
  cover: [
    {
      browser: [992, 400],
      ad_sizes: [
        [994, 200],
        [970, 250]
      ]
    },
    {
      browser: [1200, 400],
      ad_sizes: [
        [1140, 200],
        [970, 250]
      ]
    }
  ],
  product_native_sticky: [
    {
      browser: [0, 0],
      ad_sizes: ['fluid']
    }
  ],
  product_native_sidebar: [
    {
      browser: [768, 0],
      ad_sizes: ['fluid']
    }
  ]
};

export default sizeMapping;
