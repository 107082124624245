export function layoutDisableScroll(disableScroll: boolean = true) {
  const oWrapper = document.getElementById('o-wrapper');

  if (!oWrapper) {
    const err = new Error('Call layoutDisableScroll without o-wrapper in DOM');
    err.name = 'LayoutUtilsError';
    throw err;
  }

  if (disableScroll) oWrapper.classList.add('disable-scroll');
  else oWrapper.classList.remove('disable-scroll');
}
