function range(n: number) {
  return Array.from(Array(n).keys());
}

function chunkPairs<T>(inputArray: T[]) {
  return inputArray
    .map((element, i, array) =>
      i + 1 < array.length ? [element, array[i + 1]] : undefined
    )
    .filter((e): e is T[] => !!e);
}

function handleGroupSwitching(waypointIds: string[] = []) {
  // Default waypoint is ad-sidebar-group1 which is the parent of
  // iCook_Recipe(s)_Middle
  const numGroups = 1 + waypointIds.length;
  const groupIds = range(numGroups).map((i) => `ad-sidebar-group${i + 1}`);
  const groups: HTMLElement[] = groupIds
    .map((id) => document.getElementById(id))
    .filter((group): group is HTMLElement => !!group);

  if (groups.length === 0) return;

  chunkPairs(groups).forEach((pair, pairIndex) => {
    const waypoint = document.getElementById(waypointIds[pairIndex]);
    if (waypoint) {
      new Waypoint({
        element: waypoint,
        handler(direction) {
          if (direction === 'down') {
            pair[0].setAttribute('aria-hidden', 'true');
            pair[1].setAttribute('aria-hidden', 'false');
          } else if (direction === 'up') {
            pair[1].setAttribute('aria-hidden', 'true');
            pair[0].setAttribute('aria-hidden', 'false');
          }
        }
      });
    }
  });
}

export default function init() {
  const waypoints = document.getElementById('recipes_show')
    ? ['dishes']
    : ['iCook_Recipes_Middle1', 'iCook_Recipes_Bottom1'];
  handleGroupSwitching(waypoints);
}
